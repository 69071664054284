

import axios  from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
// Tokeni localStorage'da şifreli olarak saklayan ve kontrol eden bir yardımcı fonksiyon
const TokenStorage = {
  // Tokeni şifrele ve localStorage'a kaydet
  setToken: (token) => {
    try {
      localStorage.setItem('token', token); // Şifrelenmiş tokeni localStorage'a kaydet
    } catch (error) {
      console.error('Error setting token:', error);
    }
  },

  // localStorage'dan tokeni al, şifresini çöz ve kontrol et
  getToken: () => {
   
      return localStorage.getItem('token');
    },


  // localStorage'dan tokeni kaldır
  removeToken: () => {
    localStorage.removeItem('token');
  },

  validateToken: async (token) => {
    try {
        const response = await axios.post(apiUrl+'/verify', { aut: { 'token': token } });
        console.log(response.data.message);
        return response.data.message === "True";
    } catch (error) {
        console.error('Token doğrulama hatası: ', error);
        localStorage.removeItem('token');
        window.location.href='/'
        return false;
    }
}

};

export default TokenStorage;